<template>
  <div class="report-history-wrapper">
    <div class="content-wrapper">
      <van-tabs
        swipeable
        animated
        class="tab-list"
        v-model="activeTab"
        color="#0482FF"
        title-active-color="#0482FF"
        title-inactive-color="#333">
        <van-tab v-for="tab in tabs" :name="tab.key" :title="tab.name" :key="tab.key">
          <div class="report-list-wrapper">
            <component :is="tab.key"></component>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportHistory',
  data () {
    return {
      activeTab: 'reported',
      tabs: [
        {
          key: 'reported',
          name: '共上报'
        },
        {
          key: 'processed',
          name: '已处理'
        },
        {
          key: 'processing',
          name: '处理中'
        }
      ]
    }
  },
  components: {
    'reported': () => import('./reported'),
    'processed': () => import('./processed'),
    'processing': () => import('./processing')
  }
}
</script>

<style lang="scss" scoped>
  .report-history-wrapper{
    .content-wrapper{
      padding-bottom: 0px;
    }
    .report-list-wrapper{
      margin-top: 24px;
      padding-bottom: 24px;
      box-sizing: border-box;
      height: calc(100vh - 160px);
      overflow: auto;
    }
  }
</style>

<style>
  .report-history-wrapper .van-tabs__wrap{
    box-shadow:0px 4px 10px 0px rgba(221,221,221,0.5);
    border-radius:8px;
    background: #fff;
  }
</style>
